/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // GOOGLE FONTS
        WebFontConfig = {
          google: { families: [ 'Raleway:400,600,800:latin', 'Lato:300,300italic,700,700italic:latin' ] }
        };
        (function() {
          var wf = document.createElement('script');
          wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
          wf.type = 'text/javascript';
          wf.async = 'true';
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(wf, s);
        })();

        $(document).ready(function() {

        //Midnight.js
        $('nav.navbar').midnight();

        //WOW.js
        var wow = new WOW({
          boxClass:     'wow',      // animated element css class (default is wow)
          animateClass: 'animated', // animation css class (default is animated)
          offset:       0,          // distance to the element when triggering the animation (default is 0)
          mobile:       false,       // trigger animations on mobile devices (default is true)
          live:         true,       // act on asynchronously loaded content (default is true)
          callback:     function(box) {
            // the callback is fired every time an animation is started
            // the argument that is passed in is the DOM node being animated
          },
          scrollContainer: '#skrollr-body' // optional scroll container selector, otherwise use window
        });
        wow.init();

        // Arrow down
        $('.page-hero .icon-arrow-down, .hero .icon-arrow-down').on('click tap', function(){
          console.log('plop');
          $('html, body').animate({
            scrollTop: $( $.attr(this, 'data-goto') ).offset().top
          }, 700);
        });

        // Apply job
        $('.apply').on('click tap', function(){
          $('#job_apply').modal({backdrop: false});
        });
        //file name
        $( "#applicant_resume" ).change(function() {
          var filename = this.value.replace(/^.*[\\\/]/, '');
          $('#label-cv').html(filename);
        });

        $('.jobpost_form button').on('click tap', function(){
          var numbers = $('#antibot').attr('placeholder').match(/[\d\.]+/g);
          var result = $('#antibot').val();
          if (parseInt(numbers[0]) + parseInt(numbers[1]) !== parseInt(result)) {
            $('#antibot').val('');
          }
        });


      });



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        // PIE CHART
        $.easing.jswing = $.easing.swing;
        $.extend($.easing, {
          easeOutCubic: function(x, t, b, c, d) {
            return c * ((t = t / d - 1) * t * t + 1) + b;
          }
        });

        function numerate(elem, value) {
          $(elem).numerator({
            easing: 'easeOutCubic',
            duration: 2000,
            delimiter: ' ',
            rounding: 0,
            toValue: value
          });

        };

        function makePieChart(elem) {
          var percentage = $(elem).data('percentage');
          var $filling = $('circle:nth-child(2)', elem);
          var total = 2 * Math.PI * $(elem).width() / 4;

          function numberFixer(value) {
            var result = ((value * total) / 100);
            return result;
          };

          function setPieChart(value) {
            var fixedNumber = numberFixer(value);
            var result = fixedNumber + ' ' + total;
            $filling.css('stroke-dasharray', result);
          };

          setPieChart(percentage);
          numerate($('span em', elem), percentage)
          $('span', elem).animate({
            left: '-80%',
            opacity: '1'
          }, 1000);
        };

        $(document).ready(function() {


          $(".testimonials-list").slick({
            autoplay: true,
            // adaptiveHeight: true,
            slide: '.quote',
            autoplaySpeed: 8000,
            speed: 1000,
            fade: true,
            prevArrow: '<i class="icon-arrow-right slick-prev"></i>',
            nextArrow: '<i class="icon-arrow-right slick-next"></i>',

          });

          //NAVIGATION
          $('#scrollnav a').on('click tap', function(){
            $('html, body').animate({
              scrollTop: $( $.attr(this, 'href') ).offset().top
            }, 700);

            $('#scrollnav a').removeClass('active');
            $(this).addClass('active');

            return false;
          });

        // Play button
        $('#play').on('click tap', function(){
          console.log('play button');
          $.post('wp-admin/admin-ajax.php', {action: 'send_mail'});
          $('.play-btn').fadeOut();
        });



        });

        $(window).load(function() {
            //Skrollr.js
            var s = skrollr.init({
              forceHeight: false,
              skrollrBody: 'skrollr-body',
              mobileDeceleration: 0.004,
              keyframe: function(element, name, direction) {
                if (name == 'dataTop' || name == 'dataCenterTop' || name == 'data100Bottom' || name == 'dataStart') {
                  var id = element.getAttribute('id').replace("hack", "");
                  
                  $('#scrollnav').removeClass();
                  $('#scrollnav').addClass(id);

                }
                if (element.getAttribute('id') == 'services' || element.getAttribute('id') == 'presence') {
                  $('.'+element.classList[0]+' *[data-behavior="pie-chart"]').each(function(i) {
                    var e = $(this);
                    setTimeout(function(){
                      makePieChart(e);
                    }, 500*i);
                  });
                }
              }
            });

            $('body').scrollspy({ target: '#scrollnav' });
          });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page

        $(window).on('resize orientationchange', function () {
          if (window.salvattore !== undefined) {
            window.salvattore.rescanMediaQueries();
          }
        });

        $(document).ready(function() {
          $('.media').on('click', '.icon-play', function(){
            // Pause all video
            $('video').each(function (index, value) { 
              var video = $('#' + $(this).attr('id')).get(0);
              if (video.paused === false) {
                $('.icon-pause').toggleClass('icon-pause icon-play');
                video.muted = true;
                video.load();
              }
              
            });

            // Play clicked video
            $(this).toggleClass('icon-play icon-pause');
            var id_video = $(this).data('id-video');
            var video = $('#' + id_video).get(0);
            video.load();
            video.play();
            $(this).parent().find('i.icon-mute').trigger('click');

          });

          $('.media').on('click', '.icon-pause', function(){
            $(this).toggleClass('icon-pause icon-play');
            var id_video = $(this).data('id-video');
            var video = $('#' + id_video).get(0);
            video.pause();
          });

          $('.media').on('click', '.icon-mute, .icon-unmute', function(){
            $(this).toggleClass('icon-mute icon-unmute');
            var id_video = $(this).data('id-video');
            var video = $('#' + id_video).get(0);
            video.muted = !video.muted;
          });

          randomVideo();


          function randomVideo() {
            //Random video
            if ($('video').length > 1) {
              var rand = Math.floor(Math.random() * $('video').length);
              var rand_video = $('video').eq(rand);
              rand_video.parent().find('i.icon-play').trigger('click');
              rand_video.parent().find('i.icon-unmute').trigger('click');

              var current = $('#'+rand_video.attr('id'));
              current[0].muted = true;
              var i = setInterval(function() {
                if (current[0].readyState > 0) {
                  var seconds = current[0].duration % 60;
                  if (current[0].currentTime > 3) {
                    clearInterval(i);
                    randomVideo();
                  }
                }
              }, 200);
            }
          }

        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
